<template>
  <div>
    <Navbar />

    <div class="teamMain mb-4">
      <PagesHeader />
      <div class="container cards">
        <MainCardsTeam @childvalue="getchildvalue" />
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import PagesHeader from "./PagesHeader.vue";
import MainCardsTeam from "./MainCardsTeam.vue";
import Navbar from "../../../components/Navbar/newNavbar/Navbar.vue";
import Footer from "../../../components/Footer/newFooter/Footer.vue";
export default {
  name: "newTeamPage",
  components: {
    Navbar,
    Footer,
    PagesHeader,
    MainCardsTeam,
  },
  methods: {
    getchildvalue(params) {
      console.log(params);
    },
  },
};
</script>

<style scoped>
.teamMain {
  width: 100%;
  margin: auto;
  max-width: 1400px;
  min-height: calc(100vh - 190px);
}
.container {
  width: 100%;
  max-width: 1400px;
  padding: 0px;
  /* border: 2px solid red; */
}

@media all and (max-width: 1500px) {
  .cards {
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>
