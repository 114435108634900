<template>
  <div class="container">
    <div v-if="!isLoading" class="row row-cols-lg-4 g-2 g-lg-3">
      <div
        v-for="item in items"
        :key="item.id"
        class="col col-sm-6 mt-4 col-12"
      >
        <div>
          <div
            class="card"
            :style="`height: 18rem;  backgroundImage: url(${server_url}/${item.image});`"
          >
            <button
              type="button"
              class="btn btn-primary btn-lg btn-block align-self-end"
              style="margin-top: auto; height: 75px"
            >
              <router-link :to="'/team' + '/' + item.slug">
                <p
                  class="mb-0 teamName"
                  v-if="lang == 'ka'"
                  v-html="item.fullNameKA"
                >
                  {{ item.fullNameKA }}
                </p>
                <p class="mb-0 teamName" v-else v-html="item.fullNameEN">
                  {{ item.fullNameEN }}
                </p></router-link
              >
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row row-cols-lg-4 g-2 g-lg-3">
        <div
          class="col col-sm-6 mt-4 col-12"
          v-for="item in [1, 2, 3, 4, 5, 6, 7, 8]"
          :key="item"
        >
          <b-skeleton-img
            no-aspect
            height="18rem"
            type="input"
          ></b-skeleton-img>
        </div>
      </div>
    </div>
    <div class="d-flex mt-5 justify-content-center">
      <b-pagination
        v-if="items.length > 0"
        v-model="currentPage"
        :total-rows="paginationLength"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";

export default {
  props: ["items"],
  data() {
    return {
      data: {},
      lang: "ka",
      category: "medicaments",
      currentPage: 1,
      perPage: 8,
      server_url: env.server_url,
      paginationLength: "",
      isLoading: false,
    };
  },
  watch: {
    currentPage: function (newValue) {
      this.isLoading = true;
      axios
        .get(
          `${env.host}/get/pagination/team/${null}/${this.currentPage}/${
            this.perPage
          }`
        )
        .then((result) => {
          console.log(result);
          this.items = [...result.data.item];
          this.$emit("childvalue", this.items);
          this.paginationLength = result.data.total_page;
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.isLoading = true;
    axios
      .get(
        `${env.host}/get/pagination/team/null/${this.currentPage}/${this.perPage}`
      )
      .then((result) => {
        this.items = [...result.data.item];
        this.paginationLength = result.data.total_page;
        this.isLoading = false;
      })
      .catch((err) => console.log(err));
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
};
</script>

<style scoped>
* {
  font-family: mtavruli;
}
.teamName >>> * {
  color: white !important;
  font-family: mtavruli !important;
}
.card-body {
  padding: 0px;
}

.container {
  width: 100%;
  max-width: 1400px;
  padding: 0px;
}

.card {
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  padding: 0px;
  border-radius: 0px;
  align-items: center;
  border: none;
}
.button.btn {
  padding: 0px;
}

.btn-primary {
  border-radius: 0px;
  border: none;
  background-color: #7e54939f;
  padding: 15px;
}
.withOut {
  height: 60px;
  padding: 0px;
  text-align: center;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #7e5493;
  border-color: #7e5493;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-primary:hover {
  background-color: #7e5493;
  border-color: #7e5493;
}
.btn-primary:focus {
  box-shadow: none;
}
a,
a:hover,
a:visited,
a:active {
  color: inherit;
  text-decoration: none;
}
::v-deep .page-link {
  color: #bdbdbd;
}

::v-deep .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #7e5493 !important;
  border-color: #7e5493 !important;
}

::v-deep .page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #7e54935b !important;
}
</style>
